import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Container from '@parkholidays/legacy_components/dist/container'
import Typography from '@parkholidays/legacy_components/dist/typography'
import Card from '@parkholidays/legacy_components/dist/card'
import { useStyletron } from '@parkholidays/legacy_components'
import withCheckoutLayout from '../../layouts/withCheckoutLayout'
import CheckoutHeader from '../../components/CheckoutHeader'
import { paymentSuccessPrd, paymentSuccessTest, paymentFailurePrd, paymentFailureTest, paymentClientPrd, paymentClientStaging } from '../../config/urls/index'

const propTypes = {
  selectedProduct: PropTypes.shape({
    duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  }),
  reference: PropTypes.string.isRequired,
  parkId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  parkCode: PropTypes.string,
  onHandlePaymentSuccess: PropTypes.func.isRequired,
  isProduction: PropTypes.bool,
}

function generateUniqueId() {
  const dateStr = new Date().toISOString().replace(/[-:.TZ]/g, "");
  const randomStr = Math.random().toString(36).substr(2, 5);
  return dateStr + randomStr;
}

const Payment = ({ selectedProduct, reference, parkId, parkCode, onHandlePaymentSuccess, isProduction }) => {
  const [_, theme] = useStyletron()
  console.log('PARK ID', parkId, _)
  const axios = require('axios').default;
  const { duration, price } = selectedProduct
  const returnUrl = isProduction ? paymentSuccessPrd : paymentSuccessTest
  const failureReturnUrl = isProduction ? paymentFailurePrd : paymentFailureTest
  const paymentClient = isProduction ? paymentClientPrd : paymentClientStaging

  useEffect(() => {
    const data = {
      amount: price,
      // In staging/dev env a booking reference can be used only once per product type since the payment system will not allowing same reference hence using the unique id
      reference: `WIFI_${reference}_${parkCode}_${generateUniqueId()}`,
      parkCode: parkId,
      paymentDescription: `Payment for customer reference ${reference}`,
    };

    // Initialise widget
    window.DNAPayments.configure({
      isTestMode: !isProduction,
      isEnableDonation: false,
      paymentMethods: [
        {
          name: window.DNAPayments.paymentMethods.BankCard,
          message: '',
        },
      ],
      events: {
        opened: () => {
          console.log('Checkout opened');
        },
        cancelled: () => {
          console.log('Transaction cancelled');
        },
        paid: () => {
          console.log('Transaction successful');
          window.DNAPayments.closePaymentWidget();
          onHandlePaymentSuccess();
        },
        declined: (e) => {
          console.log('Transaction declined', e);
          window.DNAPayments.closePaymentWidget();
        },
      },
      embeddedWidget: {
        container: '#dna-embedded-widget-container',
        widgetType: 'EMBEDDED',
        maxVisiblePaymentMethods: 3,
        orderButtonText: `Pay £${data.amount}`,
      },
    });
    console.log('Authentication request using data', data);

    const graphqlQuery = {
      query: `query PaymentClient {
              payment_client(park_code: "${data.parkCode}" invoice_id: "${data.reference}" amount: "${data.amount}") {
                  access_token
                  expires_in
                  refresh_token
                  scope
                  token_type
                  terminal_id
              }
          }`,
    };
    axios({
      url: paymentClient,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify(graphqlQuery),
    })
      .then(function (response) {
        console.log('Initialise widget with', response);
        window.DNAPayments.openEmbeddedWidget({
          invoiceId: data.reference,
          amount: data.amount,
          currency: 'GBP',
          description: data.paymentDescription,
          paymentSettings: {
            returnUrl: returnUrl,
            failureReturnUrl: failureReturnUrl,
            terminalId: response.data.data.payment_client.terminal_id,
          },
          auth: response.data.data.payment_client,
        });
      })
      .catch(function (error) {
        console.log('Authorization error');
        if (error.response) {
          console.log(error.response.data); //May need changing depending on how the authorization request sends errors
        } else {
          console.log(error); //May need changing depending on how the authorization request sends errors
        }
      });
  }, [axios, duration, failureReturnUrl, isProduction, onHandlePaymentSuccess, parkCode, parkId, paymentClient, price, reference, returnUrl]);

  useEffect(() => {
      setTimeout(()=>{
        const bankCardEle = document.querySelector('[data-id="bank-card"]');
        if (bankCardEle)  { 
            bankCardEle.style.backgroundColor = theme.palette.corporatePale;
            bankCardEle.click();
        } 
      },2000)
  },[theme.palette.corporatePale]);

  return (
    <>
      <CheckoutHeader>
        <Container>
          <Typography as="h1" noMargins>
            Payment
          </Typography>
          <Typography as="p" noMargins>
            Please click & enter your details below.
          </Typography>
        </Container>
      </CheckoutHeader>
      <Container>
        <Card color="white" elevation={3} style={{ padding: '1rem' }}>
          <div id="dna-embedded-widget-container"></div>
        </Card>
      </Container>
    </>
  )
}

Payment.propTypes = propTypes

export default withCheckoutLayout(Payment)
